import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { optimisePostApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {
  public url = environment.api + "/api/account/adminlogin";
  forgotPasswordApi = environment.api + "/api/account/forgotpassword?email=";
  resetpasswordApi = environment.api + "/api/account/resetpassword";
  changePasswordUrl = environment.api + "/api/account/changepassword";

  constructor(private _http: HttpClient) { }

  postLoginList(data: any) {
    return optimisePostApi(this.url, data);
  }

  postChangePassword(obj: any) {
    return optimisePostApi(this.changePasswordUrl, obj);
  }

  postForgotPassword(email: any) {
    return optimisePostApi(this.forgotPasswordApi + email, email);
  }
  postResetpassword(data: any) {
    return optimisePostApi(this.resetpasswordApi, data);
  }

}
