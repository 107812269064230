<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="mt-5">
        <mat-card>
          <div class="">
            <button (click)="addToOption()" mat-raised-button id="course" color="primary" class="float-right"><svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="rgba(255,255,255,1)" />
              </svg> Add Option</button>
            <h2 class="title" (click)="backToQuizList()">Option <b>| </b> <span class="title">{{queName}}</span></h2>

            <mat-form-field class="w-25" appearance="outline" style="font-size: small;">
              <mat-label>Search Here</mat-label>
              <input matInput placeholder="Search..." (keyup)="dataFilter($event.target)">
            </mat-form-field>

            <input type="file" id="course" (change)="onFileChange($event)" class="ms-3"
              style="margin-right: -50px;cursor: pointer;">
            <button mat-raised-button id="course" color="accent" class="me-5 " (click)="onImportExcelSubmit()"
              *ngIf=!show>Upload</button>
            <button mat-raised-button color="primary" (click)="exportexcel()">Download</button>
            <button mat-raised-button color="warn" class="ms-3" (click)="deleteChekedList()" *ngIf="showBtn">Delete
              selected</button>

            <mat-table [dataSource]="optionList" matSort [trackBy]="trackByFn">

              <ng-container matColumnDef="checkList">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Select </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <mat-checkbox class="ml-2" [(ngModel)]="element.checked" (change)="check($event,element.id)">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="text">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Text </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.text}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="correctAnswer">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Correct Answer </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.correctAnswer}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="sortOrder">
                <mat-header-cell *matHeaderCellDef mat-sort-header> SortOrder </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                <mat-cell *matCellDef="let element"> <a routerLink="/home/updateOption/{{element.id}}"><i
                      class="fa fa-edit" style="font-size:25px;color:blue"></i></a><span class="mx-1"></span> <i
                    (click)="deleteRow(element.id)" class="fa fa-trash-o ml-2" style="font-size:25px;color:red"></i>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>


            </mat-table>

            <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSizeOptions]="pageSizeOptions"
              [pageSize]="pageSize" [showFirstLastButtons]="showFirstLastButtons" [pageIndex]="pageIndex-1">
            </mat-paginator>
          </div>
          <div *ngIf="_loaderService.isLoading | async"
            style="display: flex; justify-content: center; align-items: center">
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
          </div>
        </mat-card>
      </div>
    </div>
  </div>