 <div class="container-fluid" > 
   <div class="col-12">
    <div class="row" >

    <div class="d-flex align-items-center justify-content-between">
      <div class="col-8">
        
        <div class="login_image w-100">
          <img src="assets/images/sparkleweb.png" alt="image" width="100%">
        </div>
      
      </div>

      <div class="col-4" >
        
        <mat-card>
          <div class="text-center mb-2">
              <img src="assets/images/sparkleweb_logo.png" alt="logo" width="200px">
          </div>
          <h2 class="title_login text-center">Welcome Back!</h2>
          <p class="title2 text-center">Sign in to continue to Interview App</p>
          <form [formGroup]="login" id="form" class=" " novalidate>
            <mat-form-field class="input-field" class="w-100" appearance="outline">
              <mat-label>Email</mat-label>
              <input type="email" matInput formControlName="email" required>
              <mat-error *ngIf="error('email','required')">Email is required</mat-error>
            </mat-form-field>

            <mat-form-field class="input-field" class="w-100" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required>
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide" type="button">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="error('password','required')">Password is required</mat-error>
            </mat-form-field>

            <br>
            <div class="w-100">
              <a (click)="forgotPasswordNavigate()" class=" float-end my-2 text-primary materialColor ">Forgot Password?</a>

              <button mat-raised-button color="primary" type="submit" (click)="onSubmit()"
                class="w-100 py-2">Log in    <mat-icon *ngIf="loginLoader"><mat-spinner color="warn" diameter="20">
                </mat-spinner></mat-icon></button><br>
              <div class="my-2"></div>

              <!-- <p class="text-center"> Don't have an account ?<a class="text-primary" (click)="signUp()"> Register</a>
              </p> -->
              <div class="my-2"></div>

            </div>


          </form>

        </mat-card>
        <div class="alert alert-danger" role="alert" *ngIf="errorMsgHide">
          <span *ngIf="errorMsg" class="">{{msg}}</span>
        </div>
      </div>

    </div>
  </div>
  </div>

</div>
