<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="mt-5">

        <mat-card>
          <button id="course" (click)="clickToAddUser()" mat-raised-button color="primary"><svg
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="rgba(255,255,255,1)" />

            </svg> Add User</button>

          <h2 class="title">Users</h2>

          <mat-form-field class="w-25 " style="font-size: small;" appearance="outline">
            <mat-label>Search </mat-label>
            <input matInput (keyup)="dataFilter($event)" placeholder="Search...">
          </mat-form-field>

          <mat-form-field class="mx-5 w-25" appearance="outline" style="font-size: small;">
            <mat-label>Select app language</mat-label>

            <mat-select disableRipple [(ngModel)]="appLanguage" (ngModelChange)="dropDownFilter($event)">

              <mat-option value="" aria-selected="true">All Language</mat-option>
              <mat-option value="english">English</mat-option>
              <mat-option value="hindi">Hindi</mat-option>
              <mat-option value="gujarati">Gujarati</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-25" appearance="outline" style="font-size: small;">
            <mat-label>Order By Last Login</mat-label>
            <mat-select disableRipple  [(ngModel)]="orderBy" (ngModelChange)="sortingLastLogin()">
              <mat-option value="asc">Ascending order</mat-option>
              <mat-option value="desc">Descending</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-table [dataSource]="dataSource" matSort [trackBy]="trackByFn">

            <ng-container matColumnDef="firstname">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
              <mat-cell *matCellDef="let element" class="text-capitalize"> {{element.firstname}} {{element.lastname}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastname">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
              <mat-cell *matCellDef="let element" class="text-capitalize"> {{element.lastname}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Email ID </mat-header-cell>
              <mat-cell *matCellDef="let element" class="me-3"> {{element.email}} </mat-cell>
            </ng-container>


            <ng-container matColumnDef="phoneNumber">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.phoneNumber}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="appLanguage">
              <mat-header-cell *matHeaderCellDef mat-sort-header> App Language </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.appLanguage}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastLogin">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Last Login </mat-header-cell>
                <mat-cell *matCellDef="let element"> <span *ngIf="element.lastLogin">{{element.lastLogin | timeAgo }}</span> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
              <mat-cell *matCellDef="let element"> <a routerLink="/home/updateUser/{{element.id}}"><i class="fa fa-edit"
                    style="font-size:25px;color:blue"></i></a> <span class="mx-1"></span> <i
                  (click)="deleteRow(element)" class="fa fa-trash-o ml-2" style="font-size:25px;color:red"></i>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>

          <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSize]="pageSize"
            [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex-1"
            aria-label="Select page">
          </mat-paginator>
          <div class="container" *ngIf="_loaderService.isLoading | async"
            style="display: flex; justify-content: center; align-items: center;">
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
          </div>
        </mat-card>

      </div>
    </div>
  </div>
</div>