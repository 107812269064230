import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { MyUpdateVersionService } from '../myUpdateVersion.service';

@Component({
  selector: 'app-updateVersion',
  templateUrl: './updateVersion.component.html',
  styleUrls: ['./updateVersion.component.css']
})
export class UpdateVersionComponent implements OnInit {
  id: any;
  appName: any;
  version: any;
  status: any;
  status1: any;


  constructor(private _fb: FormBuilder, private _activatedRoute: ActivatedRoute, private _updateVersion: MyUpdateVersionService, private _route: Router, public _loaderService: LoaderService) { }

  updateVersion = this._fb.group({
    appName: [''],
    version: [''],
    status: []
  });


  ngOnInit() {

    this.id = localStorage.getItem('versionId');
    this.appName = localStorage.getItem('versionAPPName');
    this.version = localStorage.getItem('version');
    this.status = localStorage.getItem('versionStatus');
    this.status1 = JSON.parse(this.status);

    this.updateVersion = this._fb.group({
      id: [this.id],
      appName: [this.appName],
      version: [this.version],
      status: [this.status1]
    });


  }

  onSubmit() {


    this.updateVersion.controls['id'].setValue(this._activatedRoute.snapshot.params.id);
    this._updateVersion.updateUpdateVersionList(this.updateVersion.value).subscribe((result: any) => {

      this._route.navigate(['/home/updateVersion']);
    });


  }

  clicktobackList() {
    this._route.navigate(['/home/updateVersion']);
  }



}
