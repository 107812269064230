import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';
import { MyUserService } from '../myUser.service';


@Component({
  selector: 'app-listUser',
  templateUrl: './listUser.component.html',
  styleUrls: ['./listUser.component.css']
})



export class ListUserComponent implements OnInit {

  displayedColumns: any[] = ['firstname', 'email', 'phoneNumber', 'appLanguage','lastLogin', 'action'];
  dataSource: any;
  appLanguage = new FormControl();
  appLanguageList: string[] = ['English', 'Hindi', 'Gujarati'];
  subjectKeyUp = new Subject<any>()
  orderBy = new FormControl();

  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  userList: any;
  filterVal: any;
  constructor(private _userService: MyUserService, private _router: Router, public _loaderService: LoaderService) { }

  totalRecords = 0;
  pageSize = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {

    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;

    this.getUserList();

  }


  ngOnInit() {

    // debounceTime
    this.subjectKeyUp.pipe(debounceTime(500)).subscribe((res) => {
      this._userService.getUserList(this.pageSize, this.pageIndex, res,this.orderBy).subscribe((result) => {
        this.dataSource = result;
        this.totalRecords = this.dataSource[0].totalRecords;
      });
    })

    this.getUserList();

  }

  dataFilter(event: any) {
    this.dataSource.filter = event.target.value.trim().toLowerCase();
    this.filterVal = this.dataSource.filter
    this.subjectKeyUp.next(this.filterVal)
  }

  // language wise search //
  dropDownFilter(event: any) {
    this.dataSource.filter = this.appLanguage;
    this._userService.dropdownFilter(this.pageSize, this.pageIndex, this.dataSource.filter).subscribe((res => {
      this.totalRecords = this.dataSource[0].totalRecords;
    })
    );
  }

  trackByFn(index: number, item: any) {
    return item.id; 
  }


  sortingLastLogin(){
     this.getUserList(); 
  }

  getUserList() {
    this._userService.getUserList(this.pageSize, this.pageIndex, '', this.orderBy).subscribe((result) => {
      this.dataSource = result;
      this.totalRecords = this.dataSource[0].totalRecords;
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.dataSource.sort = this.sort;

    });


  }




  deleteRow(item: any) {


    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._userService.deleteUserList(item).subscribe((result: any) => {

          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });
  }


  clickToAddUser() {
    commonRouting('home/AddUser');
  }



}

