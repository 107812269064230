import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MyTopicService } from '../myTopic.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';


@Component({
  selector: 'app-topic-list',
  templateUrl: './topic-list.component.html',
  styleUrls: ['./topic-list.component.css']
})
export class TopicListComponent implements OnInit {
  displayedColumns: any[] = ['image', 'name', 'topicType', 'sortOrder', 'chapter', 'action'];
  dataSource: any;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  public errMsg = '';
  public courseId: any;
  public user: any = {};
  public courseNameTitle: any;
  topiclist2: any;

  constructor(private _router: Router, private _topicService: MyTopicService, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService) {
  }

  totalRecords = 0;
  pageSize = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getTopicList();
  }

  dataFilter(filterValue: any) {
    this.dataSource.filter = filterValue.value.trim().toLowerCase();
    this._topicService.getTopicList(this.courseId, this.pageSize, this.pageIndex, this.dataSource.filter).subscribe((result) => {
      this.dataSource = result;
      this.dataSource = this.dataSource.courseTopicViewModelList
      localStorage.setItem('CourseId', JSON.stringify(this.courseId));
      this.totalRecords = this.dataSource[0].totalRecords;
    });

  }

  ngOnInit(): void {

    this.courseNameTitle = localStorage.getItem('CourseTitle');

    this.courseId = this._activatedRoute.snapshot.params.id;
    this.getTopicList();
  }

  getTopicList() {
    this._topicService.getTopicList(this.courseId, this.pageSize, this.pageIndex, '').subscribe((result) => {
      this.dataSource = result;
      this.dataSource = this.dataSource.courseTopicViewModelList
      localStorage.setItem('CourseId', JSON.stringify(this.courseId));
      this.totalRecords = this.dataSource[0].totalRecords;
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.dataSource.sort = this.sort;
    },
      error => {
        this.errMsg = error;
      });

  }

  clickToAddTopic() {
    commonRouting('home/CourseTopicAdd');
  }


  trackByFn(index: number, item: any) {
    return item.id; 
  }


  deleteRow(item: any) {

    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._topicService.deleteTopicList(item).subscribe((result: any) => {

          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });

  }


  clickToChapterList(obj: any) {
    localStorage.setItem('TopicName', obj.name);
    this._router.navigate(['/home/Chapter', obj.id]);
  }

}
