import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { optimiseDeleteApi, optimiseEditApi, optimiseGetApi, optimisePostApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyCourseService {

  public url = environment.api + "/api/course";
  
  constructor() { }
 
  getCourseList(pageSize: number, pageIndex: number, search: any) {
    return optimiseGetApi(`${this.url + "/getcourselist?PageIndex="}${pageIndex}&PageSize=${pageSize}&Search=${search}`);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Unknown Server Error");
  }

  postCourseList(data: any) {
    return optimisePostApi(this.url + "/addcourse" , data ) ;
  }

  deleteCourseList(id: any) {
    return optimiseDeleteApi(`${this.url + "/deletecourse?id="}${id}`);
  }

  getList(id: number) {
    return optimiseEditApi(`${this.url + "/GetCourseById?id="}${id}`);
  }

  updateCourseList(data: any) {
    return optimiseUpdateApi(`${this.url + "/updatecourse"}`, data);
  }

}
