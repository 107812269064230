import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { commonRouting } from '../optimiseCode';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  logoPath = '/src/assets/images/Logo.png';


  constructor() { }

  ngOnInit(): void {
  }

  onLogOut() {
    Swal.fire({
      title: 'Are you sure to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("token");
        window.location.reload();
        commonRouting('login');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

}
