import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { optimiseDeleteApi, optimiseEditApi, optimiseGetApi, optimisePostApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyTopicService {

  public url = environment.api + "/api/coursetopic";

  constructor() { }

  getTopicList(id: any, pageSize: number, pageIndex: number, search: any) {
    return optimiseGetApi(`${this.url + "/getcoursetopiclistbycourseid?PageIndex="}${pageIndex}&PageSize=${pageSize}&Search=${search}&courseid=${id}&devicetype=website`).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Unknown Server Error");
  }

  postTopicList(data: any) {
    return optimisePostApi(this.url + "/addcoursetopic", data);
  }

  deleteTopicList(id: any) {
    return optimiseDeleteApi(`${this.url + "/deletecoursetopic?id="}${id}`);
  }

  getList(id: any) {
    return optimiseEditApi(`${this.url + "/GetCourseTopicById?id="}${id}`);
  }

  updateTopicList(data: any) {
    return optimiseUpdateApi(`${this.url + "/updatecoursetopic"}`, data);
  }

}
