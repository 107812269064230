import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { onSelectedFile, onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';
import { MyQuizService } from '../myQuiz.service';

@Component({
  selector: 'app-add-quiz',
  templateUrl: './add-quiz.component.html',
  styleUrls: ['./add-quiz.component.css']
})
export class AddQuizComponent implements OnInit {
  chapterId: any = localStorage.getItem('ChapterId');
  CHAPTER_ID: any = JSON.parse(this.chapterId);
  public select: any;
  selectedFile: any;
  selectedFileName: any;
  showImage = false;
  storeSortOrder: any;
  getSortOrder: any;
  parseSortOrder: any;
  autoIncreaseSortOrder: any;
  storeAutoIncreaseSortOrder: any;
  getAutoIncreaseSortOrder: any = localStorage.getItem('QuizAutoIncrease');
  public editMode: boolean = false;
  selectedFileShow: any;
  constructor(private _fb: FormBuilder, private _quizService: MyQuizService, private _router: Router, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService) { }

  previousImg: any;
  addQuizList: FormGroup = this._fb.group({
    question: ['', [Validators.required]],
    image: [''],
    imageFile: [''],
    chapterID: [this.CHAPTER_ID, [Validators.required]],
    isActive: [true, [Validators.required]],
    hindiQuestion: [''],
    gujQuestion: [''],
    sortOrder: [(localStorage.getItem('QuizSortOrder') == null ? 1 : this.getAutoIncreaseSortOrder), [Validators.required, Validators.pattern(/^[0-9]\d*$/)]]
  });


  public hasError = (controlName: string, errorName: string) => {
    return this.addQuizList.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {

    this._quizService.getList(this._activatedRoute.snapshot.params.id).subscribe((result: any) => {
      this.editMode = true;
      this.previousImg = result.image;
      this.addQuizList = this._fb.group({
        id: [result.id],
        question: [result.question, [Validators.required]],
        image: [result.image],
        imageFile: [result.imageFile],
        chapterID: [result.chapterID, [Validators.required]],
        isActive: [result.isActive, [Validators.required]],
        hindiQuestion: [result.hindiQuestion],
        gujQuestion: [result.gujQuestion],
        sortOrder: [result.sortOrder, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]]
      });
    });
  }


  onImageSubmit(event: any) {
    this.showImage = true;
    this.selectedFile = <File>event.target.files[0];
    this.selectedFileName = onSelectedFile(event);
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedFileShow = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubmit() {
    if (this.addQuizList.invalid) {
      return;
    }

    if (this._activatedRoute.snapshot.params.id == "", this._activatedRoute.snapshot.params.id == "0", this._activatedRoute.snapshot.params.id == null) {
      const formData = new FormData();
      formData.append('question', this.addQuizList.value.question);
      formData.append('hindiQuestion', this.addQuizList.value.hindiQuestion);
      formData.append('gujQuestion', this.addQuizList.value.gujQuestion);
      formData.append('isActive', this.addQuizList.value.isActive);
      formData.append('chapterID', this.addQuizList.value.chapterID);
      formData.append('sortOrder', this.addQuizList.value.sortOrder);
      formData.append('image', this.selectedFileName);
      formData.append('imageFile', this.selectedFile);
      this._quizService.postQuizList(formData).subscribe((result: any) => {
        this.storeSortOrder = localStorage.setItem('QuizSortOrder', this.addQuizList.value.sortOrder);
        this.getSortOrder = localStorage.getItem('QuizSortOrder');
        this.parseSortOrder = JSON.parse(this.getSortOrder);
        this.autoIncreaseSortOrder = this.parseSortOrder + 1;
        this.storeAutoIncreaseSortOrder = localStorage.setItem('QuizAutoIncrease', this.autoIncreaseSortOrder);
        if (result.status == "success") {
          onSweetAlertSuccess(result);
          this._router.navigate(['/home/quiz', JSON.parse(this.chapterId)]);
        } else if (result.status == "Already Exist") {
          onSweetAlertError(result);
        }
      });
    }
    else {
      this.addQuizList.controls['id'].setValue(this._activatedRoute.snapshot.params.id);
      const formData = new FormData();
      formData.append('id', this.addQuizList.value.id);
      formData.append('question', this.addQuizList.value.question);
      formData.append('hindiQuestion', this.addQuizList.value.hindiQuestion);
      formData.append('gujQuestion', this.addQuizList.value.gujQuestion);
      formData.append('isActive', this.addQuizList.value.isActive);
      formData.append('chapterID', this.addQuizList.value.chapterID);
      formData.append('sortOrder', this.addQuizList.value.sortOrder);
      formData.append('imageFile', this.selectedFile);
      if (this.selectedFileName == undefined) {
        formData.append('image', this.previousImg);
      } else {
        formData.append('image', this.selectedFileName);
      }

      this._quizService.updateQuizList(formData).subscribe((result: any) => {
        if (result.status == "success") {
          onSweetAlertSuccess(result);
          this._router.navigate(['/home/quiz', JSON.parse(this.chapterId)]);
        }
      });
    }


  }

  clicktobackList() {
    this._router.navigate(['/home/quiz', JSON.parse(this.chapterId)]);
  }

}

