import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { optimiseDeleteApi, optimiseGetApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyFeedbackService {

  constructor(private _http: HttpClient) { }

  public url = environment.api + "/api/feedback";

  getFeedBackList() {
    return optimiseGetApi(this.url + "/getfeedbacklist?devicetype=website");
  }

  deleteFeedBackList(id: any) {
    return optimiseDeleteApi(`${this.url + "/deletefeedback?Id="}${id}`);
  }


}
