import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { commonRouting } from 'src/app/optimiseCode';
import { MyAccountService } from '../myAccount.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hide = true;
  constructor(private _fb: FormBuilder, private _accountService: MyAccountService, private _router: Router) { }

  login = this._fb.group({
    email: ['', [Validators.required], [Validators.email]],
    password: ['', [Validators.required]]
  });

  public error = (control: string, error: string) => {
    return this.login.controls[control].hasError(error);
  }

  public userData: any;

  public getToken: any;

  public errorMsg = false;

  public errMsg = "Please Enter Valid Email and Password";

  public msg = "";

  white = ''
  errorMsgHide = false;
  loginLoader =false;


  ngOnInit() {
    if (localStorage.getItem('accessToken') != null) {
      commonRouting('home/UserList');
    }
  }

  onSubmit() {
    if (this.login.invalid) {
      return;
    }
    else {
      this.loginLoader = true;
      this._accountService.postLoginList(this.login.value).subscribe((result: any) => {
        this.loginLoader = false;
        const token = (<any>result).token;
        const refreshToken = (<any>result).refreshtoken;
        localStorage.setItem('token', JSON.stringify(result));
        localStorage.setItem("accessToken", token);
        localStorage.setItem("refreshToken", refreshToken);
        commonRouting('home/dashboard');
      },
        error => {
          this.loginLoader = false;
          if (error.status == 400 || 401) {
            this.errorMsgHide = true;
            this.errorMsg = true;
            this.msg = this.errMsg;
          }
        });
    }
  }

  forgotPasswordNavigate() {
    commonRouting('forgotpassword');
  }

  signUp() {
    this._router.navigate(['signUp']);
  }

}
