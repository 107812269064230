import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { optimiseGetApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyUpdateVersionService {

  constructor() { }

  public url = environment.api + "/api/appversion";

  getUpdateVersionList() {
    return optimiseGetApi(this.url + "/getupdateversionbyid?id=1");
  }

  updateUpdateVersionList(data: any) {
    return optimiseUpdateApi(`${this.url + "/updateversion"}`, data);
  }


}
