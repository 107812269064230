import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { optimiseEditApi, optimiseGetApi, optimisePostApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyUserService {

  constructor(private _http: HttpClient) { }

  public url = environment.api + "/api/account";

  getUserList(pageSize: number, pageIndex: number, search: any,orderBy:any) {
    return optimiseGetApi(`${this.url + "/getuserlist?PageIndex="}${pageIndex}&PageSize=${pageSize}&Search=${search}&OrderBy=${orderBy}`);
  }


  postUserList(data: any) {
    return optimisePostApi(`${this.url + "/register"}`, data);
  }

  deleteUserList(data: any) {
    return this._http.put(`${this.url + "/deleteuser"}`, data);
  }

  getList(id: any) {
    return optimiseEditApi(`${this.url + "/getuserbyguid?GUID="}${id}`);
  }

  updateUserList(data: any) {
    return optimiseUpdateApi(`${this.url + "/updateuserdetails?devicetype=website"}`, data);
  }

  dropdownFilter(pageIndex: number, pageSize: number, searchLanguage: string) {
    return this._http.get(`${this.url + "/getuserlist?PageIndex="}${pageIndex}&PageSize=${pageSize}&Searchbylanguage=${searchLanguage}`);
  }


}
