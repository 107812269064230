import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';
import { MyQuizService } from '../myQuiz.service';

@Component({
  selector: 'app-quiz-list',
  templateUrl: './quiz-list.component.html',
  styleUrls: ['./quiz-list.component.css']
})
export class QuizListComponent implements OnInit {
  isLoading = true;
  displayedColumns: any[] = ['image', 'question', 'sortOrder', 'option', 'action'];
  quizList: any;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  errMsg: any;
  chapterId: any;
  topicId: any;
  chaptername: any;
  public quizlist2: any;

  constructor(private _router: Router, private _quizService: MyQuizService, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService) { }
  totalRecords = 0;
  pageSize = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;
  handlePageEvent(event: PageEvent) {
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getQuizList();
  }
  ngOnInit() {
    this.topicId = localStorage.getItem('TopicId');
    this.chaptername = localStorage.getItem('ChapterName');
    this.chapterId = this._activatedRoute.snapshot.params.id;

    this.getQuizList();
  }

  dataFilter(filterValue: any) {
    this.quizList.filter = filterValue.value.trim().toLowerCase();
    this._quizService.getQuizList(this.chapterId, this.pageSize, this.pageIndex, this.quizList.filter).subscribe((result: any) => {
      this.quizList = result;
      this.isLoading = false;
      localStorage.setItem('ChapterId', JSON.stringify(this.chapterId));
      this.totalRecords = this.quizList[0].totalRecords;
    })
  }


  getQuizList() {
    this._quizService.getQuizList(this.chapterId, this.pageSize, this.pageIndex, '').subscribe((result: any) => {
      this.quizList = result;
      this.isLoading = false;
      localStorage.setItem('ChapterId', JSON.stringify(this.chapterId));
      this.totalRecords = this.quizList[0].totalRecords;
      this.quizList = new MatTableDataSource(this.quizList);
      this.quizList.sort = this.sort;
    },
      error => {
        this.errMsg = error;
      });
  }

  trackByFn(index: number, item: any) {
    return item.id; 
  }


  deleteRow(item: any) {

    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._quizService.deleteQuizList(item).subscribe((result: any) => {

          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });

  }

  backToChapterList() {
    this.topicId = localStorage.getItem('TopicId');
    this._router.navigate(['/home/Chapter', JSON.parse(this.topicId)]);
  }

  clickToAddQuiz() {
    commonRouting('home/QuizAdd');
  }

  clickToOptionList(obj: any) {
    localStorage.setItem('Question', obj.question);
    this._router.navigate(['/home/option', obj.id]);
  }




}
