<div class="mt-5">
  <div class="row">
    <div class="col-12">
      <mat-card>
        <div>
          <div class="container">
            <h1 class="title ms-3">{{editMode?'Update':'Add'}} Option</h1>

            <form [formGroup]="addOption" (ngSubmit)="onSubmit()">
              <div class="d-flex">
                <div class="col-6">

                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Text </mat-label>
                    <input matInput formControlName="text">
                    <mat-error *ngIf="hasError('text', 'required')">Please enter Text</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field appearance="outline" class="w-100" hidden>
                    <mat-label>Quiz Id</mat-label>
                    <input matInput formControlName="quizID">
                    <mat-error *ngIf="hasError('quizID', 'required')">Please enter Quiz Id</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Hindi Text </mat-label>
                    <input matInput formControlName="hindiText">
                    <mat-error *ngIf="hasError('hindiText', 'required')">Please enter Hindi Text</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="d-flex">
                <div class="col-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Gujarati Text </mat-label>
                    <input matInput formControlName="gujText">
                    <mat-error *ngIf="hasError('gujText', 'required')">Please enter Gujarati Text</mat-error>
                  </mat-form-field>
                  <p>
                    <mat-checkbox color="primary" formControlName="correctAnswer">Correct Answer</mat-checkbox>
                  </p>
                </div>
                <div class="col-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>SortOrder</mat-label>
                    <input matInput formControlName="sortOrder">
                    <mat-error *ngIf="hasError('sortOrder', 'required')">SortOrder is required</mat-error>
                    <mat-error *ngIf="hasError('sortOrder', 'pattern')">Only numbers are allowed</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="ms-3">
                <button mat-raised-button color="primary" type="submit"
                  class="me-2">{{editMode?'Update':'Submit'}}</button>

                <button mat-stroked-button color="primary" (click)="clicktobackList()">Cancel</button>

              </div>

            </form>

          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<!-- loading spinner -->
<div class="spinner" *ngIf="_loaderService.isLoading | async"
  style="display: flex; justify-content: center; align-items: center ; ">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>