import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MyAccountService } from '../myAccount.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private _fb: FormBuilder, private _myAccountService: MyAccountService, private router: Router) { }

  forgotPasswordForm = this._fb.group({
    email: ['', [Validators.required, Validators.pattern]],
  });

  public error = (control: string, error: string) => {
    return this.forgotPasswordForm.controls[control].hasError(error);
  }

  ngOnInit(): void {
  }


  postForgotPassword() {
    if (this.forgotPasswordForm.invalid) {
      return
    }
    else {
      this._myAccountService.postForgotPassword(this.forgotPasswordForm.value.email)
        .subscribe((res: any) => {
          if (res.status == 'success') {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: res.message,
            }).then(res => {
              if (res.isConfirmed)
                this.router.navigate(['login']);
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: res.message,
            });
          }
        })
    }
  }


}
