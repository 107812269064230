import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MyAccountService } from '../myAccount.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  hide = true;
  hide2 = true;
  hide3 = true;
  email: any
  token: any = []
  constructor(private fb: FormBuilder, private _myAccountService: MyAccountService,
  private router:Router) { }
  changePasswordForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    oldPassword: ['', [Validators.required, Validators.pattern(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'
    ),]],
    newPassword: ['', [Validators.required, Validators.pattern(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'
    ),]],
    confirmNewPssword: ['', [Validators.required,]]
  },
    {
      validators: MustMatch('newPassword', 'confirmNewPssword')
    }

  )

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    let tokenConvert = JSON.parse(this.token)
    this.email = tokenConvert.email
  }

  public error = (control: string, error: string) => {
    return this.changePasswordForm.controls[control].hasError(error);
  }

  postChangePassword(data: any) {

    if (this.changePasswordForm.invalid) {
      return

    }
    else {
      this._myAccountService.postChangePassword(data).subscribe((res: any) => {
        if (res.status == "Success") {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: res.message,
            confirmButtonText: 'OK',
            timer: 5000
          }).then((res) => {
            if (res.isConfirmed) {
              this.refreshComponent();
            }
          })
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: res.message,
            confirmButtonText: 'OK',
            timer: 5000
          })
        }
      })
    }
  }

  refreshComponent() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

}
export function MustMatch(controlName: any, matchingControlName: any) {

  {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.MustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      else if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ MustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

}