import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { optimiseDeleteApi, optimiseEditApi, optimiseGetApi, optimisePostApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyQuizService {

  public url = environment.api + "/api/quiz";

  constructor() { }

  getQuizList(id: any, pageSize: number, pageIndex: number, search: any) {
    return optimiseGetApi(`${this.url + "/getquizbychapterid?PageIndex="}${pageIndex}&PageSize=${pageSize}&Search=${search}&ChapterId=${id}`).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Unknown server error");
  }

  postQuizList(data: any) {
    return optimisePostApi(this.url + "/addquiz", data);
  }

  deleteQuizList(id: any) {
    return optimiseDeleteApi(`${this.url + "/deletequiz?id="}${id}`);
  }

  getList(id: any) {
    return optimiseEditApi(`${this.url + "/getquizbyid?id="}${id}` + "&devicetype=website&email=");
  }

  updateQuizList(formData: any) {
    return optimiseUpdateApi(`${this.url + "/updatequiz"}`, formData);
  }
}
