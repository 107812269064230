<div class="row">
  <div class="col-12">
    <div class="container">
      <mat-card class="mt-5">
        <form id="form" [formGroup]="userlist">

          <div class="container">
            <h2 class="title ms-3">Add User</h2>

            <div class="d-flex">
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>First Name</mat-label>
                  <input matInput formControlName="firstname">
                  <mat-error *ngIf="error('firstname','required')">Please enter first name</mat-error>
                </mat-form-field>
              </div>

              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="lastname">
                  <mat-error *ngIf="error('lastname','required')">Please enter last name</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="d-flex">
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Email ID</mat-label>
                  <input matInput formControlName="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                  <mat-error *ngIf="error('email','required')">Please enter email id</mat-error>
                  <mat-error *ngIf="error('email','pattern')">Please Enter Valid Email Id</mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Phone Number</mat-label>
                  <input matInput formControlName="phoneNumber" maxlength="10">
                  <mat-error *ngIf="error('phoneNumber','required')">Please enter phone number</mat-error>
                  <mat-error *ngIf="error('phoneNumber','pattern')">Please Enter 10 digits only</mat-error>
                </mat-form-field>
              </div>

            </div>


            <div class="d-flex">
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select Language</mat-label>
                  <mat-select disableRipple formControlName="appLanguage">
                    <mat-option value="English">English</mat-option>
                    <mat-option value="Hindi">Hindi</mat-option>
                    <mat-option value="Gujarati">Gujarati</mat-option>
                  </mat-select>
                  <mat-error *ngIf="error('appLanguage','required')">Please select app language</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select Course</mat-label>
                  <mat-select formControlName="courseId" multiple>
                    <mat-option *ngFor="let couse of courseList" [value]="couse.id">{{couse.name}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="error('courseId','required')">Please select course</mat-error>
                </mat-form-field>
              </div>

              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Password</mat-label>
                  <input matInput formControlName="password" [type]="hide ? 'password' : 'text'"
                    pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}">
                  <button mat-icon-button color="primary" matSuffix (click)="hide = !hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-error *ngIf="error('password','required')">Please enter password</mat-error>
                  <mat-error *ngIf="error('password','pattern')">Password should have Minimum 8 Characters and atleast 1
                    uppercase 1
                    lowercase 1 special character 1 number.</mat-error>
                </mat-form-field>
              </div>



            </div>


            <div class="ms-3">
              <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">Submit</button>
              <button mat-stroked-button color="primary" class="ms-2" [routerLink]="['/home/UserList']">Cancel</button>
            </div>

          </div>



        </form>


      </mat-card>


    </div>
  </div>
</div>







<!-- loading spinner -->
<div class="spinner" *ngIf="isLoading">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>