import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { optimiseDeleteApi, optimiseEditApi, optimiseGetApi, optimisePostApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyChapterService {

  public url = environment.api + "/api/chapter";

  constructor() { }

  getChapterList(id: any, pageSize: number, pageIndex: number, search: any) {
    return optimiseGetApi(`${this.url + "/getchapterlistbycoursetopicid?PageIndex="}${pageIndex}&PageSize=${pageSize}&Search=${search}&CourseTopicId=${id}`).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Unknown Server Error");
  }

  postChapterList(data: any) {
    return optimisePostApi(this.url + "/addchapter", data);
  }

  deleteChapterList(id: any) {
    return optimiseDeleteApi(`${this.url + "/deletechapter?id="}${id}`);
  }

  getList(id: any) {
    return optimiseEditApi(`${this.url + "/getchapterbyid?id="}${id}` + "&devicetype=website&email=");
  }

  updateChapterList(data: any) {
    return optimiseUpdateApi(`${this.url + "/updatechapter"}`, data);
  }

}
