import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MyCourseService } from '../myCourse.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.css']
})
export class CourseListComponent implements OnInit {
  displayedColumns: any[] = ['image', 'name', 'type', 'shortDescription', 'sortOrder', 'question', 'action'];
  courselist: any;
  dataSource: any;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;



  public errMsg = "";
  public product_id: any;
  constructor(private _route: Router, private _courseService: MyCourseService, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService) { }

  totalRecords = 0;
  pageSize = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {
    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getCourseList();

  }

  dataFilter(event: any) {
    this.courselist.filter = event.target.value.trim().toLowerCase();

    this._courseService.getCourseList(this.pageSize, this.pageIndex, this.courselist.filter).subscribe((result) => {
      this.courselist = result;
      this.totalRecords = this.courselist[0].totalRecords;
    });

  }



  ngOnInit(): void {
    this.getCourseList();
  }



  getCourseList() {
    this._courseService.getCourseList(this.pageSize, this.pageIndex, '').subscribe((result: any) => {
      this.courselist = result;
      console.warn(this.courselist[0].totalRecords);
      this.totalRecords = this.courselist[0].totalRecords;
      this.courselist = new MatTableDataSource(this.courselist);
      this.courselist.sort = this.sort;
    },
      error => {
        this.errMsg = error;
      });
  }

  trackByFn(index: number, item: any) {
    return item.id; // replace `id` with your unique identifier
  }

  clickToAddCourse() {
    commonRouting('home/CourseAdd');
  }


  deleteRow(item: any) {
    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._courseService.deleteCourseList(item).subscribe((result: any) => {

          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          } else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });

  }


  clickToTopicList(obj: any) {
    localStorage.setItem('CourseTitle', obj.name);
    localStorage.setItem('CourseId', obj.id);
    this._route.navigate(['/home/CourseTopic', obj.id]);

  }
}
