import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { optimiseDeleteApi, optimiseEditApi, optimiseGetApi, optimisePostApi, optimiseUpdateApi } from '../optimiseCode';

@Injectable({
  providedIn: 'root'
})
export class MyOptionService {

  public url = environment.api + "/api/options";

  constructor(private _http: HttpClient) { }

  getOptionList(id: any, pageIndex: number, pageSize: number, search: any) {
    return optimiseGetApi(`${this.url + "/getoptionsbyquizid?PageIndex="}${pageIndex}&PageSize=${pageSize}&QuizId=${id}&Search=${search}`).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Unknown server error");
  }

  postOptionList(data: any) {
    return optimisePostApi(this.url + "/addoption", data);
  }

  deleteOptionList(id: any) {
    return optimiseDeleteApi(`${this.url + "/deleteoption?id="}${id}`);
  }

  getList(id: any) {
    return optimiseEditApi(`${this.url + "/getoptionbyid?id="}${id}` + "&devicetype=website&email=");
  }

  updateOptionList(data: any) {
    return optimiseUpdateApi(`${this.url + "/updateoption"}`, data);
  }

  importExcel(data: any) {

    return optimisePostApi(this.url + "/ImportExcelOption", data);
  }

  // multiple delete method //
  deleteChekedList(id: any) {
    return optimiseDeleteApi(this.url + "/deletemultipleoptions?ids=" + id);
  }

  exportToExcelOptionList(id: any) {

    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    return this._http.post(`${this.url + "/ExportExcelOption?QuizId="}${id}`, id, {
      responseType: 'arraybuffer'
    });

  }

}
