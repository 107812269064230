import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddChapterComponent } from './Chapter/addChapter/addChapter.component';
import { AddCourseComponent } from './course/add-course/add-course.component';
import { AddTopicComponent } from './Topic/add-topic/add-topic.component';
import { ChapterListComponent } from './Chapter/chapterList/chapterList.component';
import { CourseListComponent } from './course/course-list/course-list.component';
import { TopicListComponent } from './Topic/topic-list/topic-list.component';
import { QuizListComponent } from './Quiz/quiz-list/quiz-list.component';
import { AddQuizComponent } from './Quiz/add-quiz/add-quiz.component';
import { OptionListComponent } from './Option/option-list/option-list.component';
import { AddOptionComponent } from './Option/add-option/add-option.component';
import { LoginComponent } from './accout/login/login.component';
import { AuthGuard } from './guard/auth.guard';
import { UserComponent } from './user-list/user/user.component';
import { ListUserComponent } from './user-list/listUser/listUser.component';
import { ActivateGuard } from './authentication/activate.guard';
import { HeaderComponent } from './header/header.component';
import { ForgotPasswordComponent } from './accout/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './accout/change-password/change-password.component';
import { HomeComponent } from './HOME/home/home.component';
import { FeedbackComponent } from './FEEDBACK/feedback/feedback.component';
import { UpdateVersionListComponent } from './Update-Version/updateVersionList/updateVersionList.component';
import { UpdateVersionComponent } from './Update-Version/updateVersion/updateVersion.component';
import { UpdateUserComponent } from './user-list/updateUser/updateUser.component';
import { ResetPasswordComponent } from './accout/forgot-password/reset-password/reset-password.component';

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  {
    path: "home", component: HeaderComponent, canActivate: [AuthGuard], children: [
      { path: "dashboard", component: HomeComponent, canActivate: [ActivateGuard] },
      { path: "Course", component: CourseListComponent, canActivate: [ActivateGuard] },
      { path: "CourseAdd", component: AddCourseComponent, canActivate: [ActivateGuard] },
      { path: "Course/:id", component: AddCourseComponent, canActivate: [ActivateGuard] },
      { path: "CourseTopic", component: TopicListComponent, canActivate: [ActivateGuard] },
      { path: "CourseTopic/:id", component: TopicListComponent, canActivate: [ActivateGuard] },
      { path: "CourseTopicAdd", component: AddTopicComponent, canActivate: [ActivateGuard] },
      { path: "Chapter/:id", component: ChapterListComponent, canActivate: [ActivateGuard] },
      { path: "ChapterAdd", component: AddChapterComponent, canActivate: [ActivateGuard] },
      { path: "updateTopic/:id", component: AddTopicComponent, canActivate: [ActivateGuard] },
      { path: "updateChapter/:id", component: AddChapterComponent, canActivate: [ActivateGuard] },
      { path: "quiz", component: QuizListComponent, canActivate: [ActivateGuard] },
      { path: "quiz/:id", component: QuizListComponent, canActivate: [ActivateGuard] },
      { path: "QuizAdd", component: AddQuizComponent, canActivate: [ActivateGuard] },
      { path: "updateQuiz/:id", component: AddQuizComponent, canActivate: [ActivateGuard] },
      { path: "option", component: OptionListComponent, canActivate: [ActivateGuard] },
      { path: "option/:id", component: OptionListComponent, canActivate: [ActivateGuard] },
      { path: "OptionAdd", component: AddOptionComponent, canActivate: [ActivateGuard] },
      { path: "updateOption/:id", component: AddOptionComponent, canActivate: [ActivateGuard] },
      { path: "AddUser", component: UserComponent, canActivate: [ActivateGuard] },
      { path: "UserList", component: ListUserComponent, canActivate: [ActivateGuard] },
      { path: "updateUser/:id", component: UpdateUserComponent, canActivate: [ActivateGuard] },
      { path: "changepassword", component: ChangePasswordComponent, canActivate: [ActivateGuard] },
      { path: "feedBack", component: FeedbackComponent, canActivate: [ActivateGuard] },
      { path: "updateVersion", component: UpdateVersionListComponent, canActivate: [ActivateGuard] },
      { path: "updatedVersion/:id", component: UpdateVersionComponent, canActivate: [ActivateGuard] }
    ]
  },
  { path: "forgotpassword", component: ForgotPasswordComponent },
  { path: "signUp", component: UserComponent },
  { path: "resetpassword", component: ResetPasswordComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
