<div class="mt-5">
  <div class="row">
    <div class="col-12">
      <mat-card>
        <div class="container">
          <h2 class="ms-3 title">Update Version</h2>
          <form [formGroup]="updateVersion" (ngSubmit)="onSubmit()">

            <div class="d-flex">
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>AppName</mat-label>
                  <input matInput formControlName="appName">

                </mat-form-field>
                <p>

                  <mat-slide-toggle formControlName="status" color="primary">Status</mat-slide-toggle>

                </p>

              </div>
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Version</mat-label>
                  <input matInput formControlName="version">
                </mat-form-field>

              </div>
            </div>


            <div class="ms-3">
              <button class="me-2" mat-raised-button color="primary">Submit</button>
              <button mat-stroked-button color="primary" class="" (click)="clicktobackList()">Cancel</button>
            </div>

          </form>

        </div>

        <mat-card *ngIf="_loaderService.isLoading | async"
          style="display: flex; justify-content: center; align-items: center;">
          <mat-progress-spinner color="primary" mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>

      </mat-card>
    </div>
  </div>