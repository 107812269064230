import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MyOptionService } from '../myOption.service';
import * as FileSaver from 'file-saver';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertDelete, onSweetAlertDeletedError, onSweetAlertDeletedSuccess } from 'src/app/optimiseCode';


@Component({
  selector: 'app-option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.css']
})
export class OptionListComponent implements OnInit {

  propertyName = "testing";
  show: boolean = true;

  displayedColumns: any = ['checkList', 'text', 'correctAnswer', 'sortOrder', 'action'];
  optionList: any;
  data: any;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  quizId: any;
  chapterId: any;
  queName: any;
  file: any;
  showBtn = false;
  sMsg: any;
  selectedId: any = [];
  fileName = 'OptionList';

  constructor(private _optionService: MyOptionService, private _router: Router, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService) { }

  totalRecords = 0;
  pageSize = 10;
  pageIndex = 1;
  pageSizeOptions = [5, 10, 25, 50];
  showFirstLastButtons = true;

  handlePageEvent(event: PageEvent) {

    this.totalRecords = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;

    this.getOptionList();


  }


  ngOnInit() {
    this.queName = localStorage.getItem('Question');
    this.quizId = this._activatedRoute.snapshot.params.id;
    this.getOptionList()
  }

  getOptionList() {

    this._optionService.getOptionList(this.quizId, this.pageIndex, this.pageSize, '').subscribe((result: any) => {
      this.optionList = result;
      localStorage.setItem('QuizId', JSON.stringify(this.quizId));
      this.chapterId = localStorage.getItem('ChapterId');
      this.optionList = new MatTableDataSource(this.optionList);
      this.optionList.sort = this.sort;
      this.totalRecords = this.optionList[0].totalRecords;
    });
  }



  backToQuizList() {
    this.chapterId = localStorage.getItem('ChapterId');
    this._router.navigate(['/home/quiz', JSON.parse(this.chapterId)]);
  }

  addToOption() {
    commonRouting('home/OptionAdd');
  }

  deleteRow(item: any) {

    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._optionService.deleteOptionList(item).subscribe((result: any) => {

          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
          }
          else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });
  }

  dataFilter(filterValue: any) {
    this.optionList.filter = filterValue.value.trim().toLowerCase();
    this._optionService.getOptionList(this.quizId, this.pageIndex, this.pageSize, this.optionList.filter).subscribe((result: any) => {
      this.optionList = result;
      localStorage.setItem('QuizId', JSON.stringify(this.quizId));
      this.chapterId = localStorage.getItem('ChapterId');
      this.optionList = new MatTableDataSource(this.optionList);
      this.optionList.sort = this.sort;
      this.optionList.paginator = this.paginator;
    });
  }


  onFileChange(event: any) {
    this.file = <File>event.target.files[0]
    this.show = false

  }



  onImportExcelSubmit() {
    const formData = new FormData();
    formData.append('postedFile', this.file);
    this._optionService.importExcel(formData).subscribe((res: any) => {

      this.ngOnInit();
    });
  }

  check(event: any, id: any) {
    if (event.checked == true) {
      this.selectedId.push(id)
      this.showBtn = true;
    }
    else {
      this.selectedId.forEach((item: any, index: any) => {
        if (item == id) {
          this.selectedId.splice(index, 1);
        }
      });
    }
  }

  trackByFn(index: number, item: any) {
    return item.id; 
  }


  deleteChekedList() {

    onSweetAlertDelete().then((isDeleted) => {
      if (isDeleted.isConfirmed) {
        this._optionService.deleteChekedList(this.selectedId).subscribe((result: any) => {

          this.ngOnInit();
          if (result.status == "success") {
            onSweetAlertDeletedSuccess(result);
            this.showBtn = false;
          }
          else if (result.status == "error") {
            onSweetAlertDeletedError(result);
          }
        });
      }
    });
  }

  // export to excel start //
  exportexcel() {


    this._optionService.exportToExcelOptionList(this.quizId).subscribe((res: any) => {
      this.downloadFile(res)

    })
  }

  // download file mathod //
  downloadFile(data: Blob) {

    const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const blob = new Blob([data], { type: contentType });
    const url = FileSaver.saveAs(blob, this.fileName + '.xlsx');
  }

}
