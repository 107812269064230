<div class="container">

  <div class="mt-5">

    <mat-card class="">
      <h2 class="title">Forgot Password?</h2>
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="postForgotPassword()">

        <mat-form-field class="w-50" appearance="outline">
          <mat-label>Enter your email</mat-label>
          <input matInput placeholder="pat@example.com" formControlName="email" required>
          <mat-error *ngIf="error('email','pattern')">Please enter valid Email ID </mat-error>
          <mat-error *ngIf="error('email' ,'required')">Please enter your Email ID</mat-error>
        </mat-form-field>

        <div>
          <button mat-raised-button color="primary" type="submit" class=" ms-2 p-1 w-25">Send</button>
        </div>
      </form>
    </mat-card>

  </div>
</div>