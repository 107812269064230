<p>{{errMsg}}</p>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="mt-4">
        <mat-card>
          <div class="">
            <button (click)="clickToAddQuiz()" mat-raised-button color="primary" id="course"><svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="rgba(255,255,255,1)" />
              </svg> Add Quiz</button>
          </div>
          <h2 class="title" (click)="backToChapterList()">Quiz <b>| </b> <span id="span"
              class="title">{{chaptername}}</span></h2>

          <mat-form-field class="w-25" appearance="outline" style="font-size: small;">
            <mat-label>Search here</mat-label>
            <input (keyup)="dataFilter($event.target)" matInput placeholder="Search...">
          </mat-form-field>

          <div class="">

            <mat-table [dataSource]="quizList" matSort [trackBy]="trackByFn">


              <ng-container matColumnDef="question">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Question </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.question}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="image">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Image </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <img src="https://interview-test.sparkleweb.co.in/content/images/quiz/{{element.image}}" width="30px">

                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="sortOrder">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Sort Order </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="option">
                <mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
                <mat-cell *matCellDef="let element"><a (click)="clickToOptionList(element)"><button mat-stroked-button
                      color="accent" id="topic">Option</button></a>

                </mat-cell>
              </ng-container>



              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                <mat-cell *matCellDef="let element"> <a routerLink="/home/updateQuiz/{{element.id}}"><i
                      class="fa fa-edit" style="font-size:25px;color:blue"></i></a> <span class="mx-1"></span><i
                    (click)="deleteRow(element.id)" class="fa fa-trash-o ml-2" style="font-size:25px;color:red"></i>
                </mat-cell>
              </ng-container>



              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>



            <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords" [pageSizeOptions]="pageSizeOptions"
              [pageSize]="pageSize" [showFirstLastButtons]="showFirstLastButtons" [pageIndex]="pageIndex-1">
            </mat-paginator>
          </div>

          <div *ngIf="_loaderService.isLoading | async"
            style="display: flex; justify-content: center; align-items: center">
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
          </div>

        </mat-card>
      </div>
    </div>
  </div>