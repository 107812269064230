<div class="my-2">
  <div class="row">
    <div class="col-12">
      <mat-card>
        <div class="container">
          <div class="">
            <h2 class="title">{{editMode?'Edit':'Add'}} Question</h2>
            <form id="form" [formGroup]="addChapterList" (ngSubmit)="onSubmit()">

              <div>
                <div class="d-flex">
                  <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Question Name</mat-label>
                      <input matInput formControlName="name">
                      <mat-error *ngIf="hasError('name', 'required')">Please enter Question Name</mat-error>
                    </mat-form-field> <br>

                    <mat-label><b>Question Description :- </b></mat-label>
                    <editor formControlName="description" class="w-100" apiKey="your-api-key" [init]="{
                                    base_url: '/tinymce', 
                              suffix: '.min',    
                              height: 150,
                              menubar: true,
                              content_style:
                              'body { background: #f1f2f7; color: black; font-family: verdana; }',
                              plugins: [
                               'advlist autolink lists link image charmap print preview anchor',
                               'searchreplace visualblocks code fullscreen',
                               'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar:
                               'undo redo | formatselect | bold italic backcolor | \
                               alignleft aligncenter alignright alignjustify | \
                               bullist numlist outdent indent | removeformat | help'
                              }"></editor>
                    <mat-error *ngIf="hasError('description', 'required')">Note : This field is required</mat-error>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Hindi Question Name</mat-label>
                      <input matInput formControlName="hindiName">
                      <mat-error *ngIf="hasError('hindiName', 'required')">Please enter Hindi Question Name</mat-error>
                    </mat-form-field><br>

                    <mat-label><b>Hindi Question Description :- </b></mat-label>
                    <editor formControlName="hindiDescription" class="w-100" apiKey="your-api-key" [init]="{
                    base_url: '/tinymce', 
                  suffix: '.min',    
                  height: 150,
                  menubar: true,
                  content_style:
                  'body { background: #f1f2f7; color: black; font-family: verdana; }',
                  plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
                  }"></editor>
                    <mat-error *ngIf="hasError('hindiDescription', 'required')">Note : This field is required
                    </mat-error>
                  </div>

                </div>

                <div class="d-flex">
                  <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Gujarati Question Name</mat-label>
                      <input matInput formControlName="gujName">
                      <mat-error *ngIf="hasError('gujName', 'required')">Please enter Gujarati Question Name</mat-error>
                    </mat-form-field><br>

                    <mat-label><b>Gujarati Question Description :- </b></mat-label>
                    <editor formControlName="gujDescription" class="w-100" apiKey="your-api-key" [init]="{
              base_url: '/tinymce', 
            suffix: '.min',    
            height: 150,
            menubar: true,
            content_style:
            'body { background: #f1f2f7; color: black; font-family: verdana; }',
            plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help'
            }"></editor>
                    <mat-error *ngIf="hasError('gujDescription', 'required')">Note : This field is required</mat-error>


                    <mat-form-field appearance="outline" class="w-100" hidden>
                      <mat-label>Topic Id </mat-label>
                      <input matInput formControlName="courseTopicID">
                      <mat-error *ngIf="hasError('courseTopicID', 'required')"> Please enter Topic Id </mat-error>
                    </mat-form-field><br>

                    <p>
                      <mat-slide-toggle formControlName="isActive" color="primary">Status</mat-slide-toggle>

                      <mat-slide-toggle formControlName="isQuiz" color="primary" class="ms-3">Show Quiz
                      </mat-slide-toggle>
                    </p>
                  </div>

                  <div class="col-6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Type</mat-label>
                      <mat-select disableRipple formControlName="type">
                        <mat-option value="List">List</mat-option>
                        <mat-option value="Tiles">Tiles</mat-option>
                      </mat-select>
                      <mat-error *ngIf="hasError('type', 'required')">Please select Chapter Type</mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>SortOrder</mat-label>
                      <input matInput formControlName="sortOrder">
                      <mat-error *ngIf="hasError('sortOrder', 'required')">SortOrder is required</mat-error>
                      <mat-error *ngIf="hasError('sortOrder', 'pattern')">Only numbers are allowed</mat-error>
                    </mat-form-field>

                    <div>
                      <input type="file" class="w-100" formControlName="imageFile" (change)="onImageSubmit($event)" />
                      <h2>{{previousImg}}</h2>
                      <p *ngIf="previousImg">Previous Image</p>
                      <img *ngIf="previousImg"
                        src="https://interview-test.sparkleweb.co.in/content/images/chapter/{{previousImg}}"
                        formControlName="image" width="100px" height="100px">

                      <span class="mx-2"></span>
                      <img [src]="selectedFileShow" formControlName="image" width="100px" height="100px"
                        *ngIf="showImage">
                      <h2>{{selectedFileName}}</h2>
                    </div>
                  </div>

                </div>

                <div class="ms-3">
                  <button mat-raised-button color="primary" class="button1 me-2"
                    type="submit">{{editMode?'Update':'Submit'}}</button>
                  <button mat-stroked-button color="primary" (click)="clicktobackList()">Cancel</button>
                </div>

              </div>




            </form>

          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>


<!-- loading spinner -->
<div class="spinner" *ngIf="_loaderService.isLoading | async"
  style="display: flex; justify-content: center; align-items: center ; ">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>