<!-- <p>{{errMsg}}</p> -->


<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="mt-4">
                <div class="mb-2 backBtn">
                </div>
                <div>

                    <mat-card>
                        <div class="">
                            <button (click)="clickToAddChapter()" mat-raised-button color="primary" id="chapter"><svg
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" fill="rgba(255,255,255,1)" />
                                </svg> Add Question</button>
                        </div>
                        <h2 class="title" (click)="backToTopicList()">Question <b>| </b> <span id="span"
                                class="title">{{topicname}}</span></h2>
                        <mat-form-field class="w-25" appearance="outline" style="font-size: small;">
                            <mat-label>Search here</mat-label>
                            <input (keyup)="dataFilter($event.target)" matInput placeholder="Search...">
                        </mat-form-field>

                        <div class="table_content">

                            <mat-table [dataSource]="dataSource" matSort id="table" [trackBy]="trackByFn">

                                <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Question Name </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="w-100"> {{element.name}} </mat-cell>
                                </ng-container>


                                <ng-container matColumnDef="image">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header class="w-100"> Image
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="w-100"><img
                                            src="https://interview-test.sparkleweb.co.in/content/images/chapter/{{element.image}}"
                                            width="30px">

                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="type">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header class="w-75"> Type
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="ms-3  w-75"> {{element.type}} </mat-cell>
                                </ng-container>



                                <ng-container matColumnDef="description">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header class="w-100"> Description
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="w-100"><span
                                            [innerHTML]="element.description" class="description"></span> </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="sortOrder">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> Sort Order </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="w-100"> {{element.sortOrder}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="quiz">
                                    <mat-header-cell *matHeaderCellDef> Quiz List </mat-header-cell>
                                    <mat-cell *matCellDef="let element"><a (click)="clickToQuiz(element)"><button
                                                mat-stroked-button color="accent" id="topic">Start Quiz</button></a>

                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="action">
                                    <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> <a
                                            routerLink="/home/updateChapter/{{element.id}}"><i class="fa fa-edit"
                                                style="font-size:25px;color:blue"></i></a> <span class="mx-1"></span><i
                                            (click)="deleteRow(element.id)" class="fa fa-trash-o ml-2"
                                            style="font-size:25px;color:red"></i>
                                    </mat-cell>
                                </ng-container>


                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                            </mat-table>

                            <mat-paginator (page)="handlePageEvent($event)" [length]="totalRecords"
                                [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                                [showFirstLastButtons]="showFirstLastButtons" [pageIndex]="pageIndex-1">
                            </mat-paginator>
                        </div>
                        <div *ngIf="_loaderService.isLoading | async"
                            style="display: flex; justify-content: center; align-items: center">
                            <mat-progress-spinner color="primary" mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>