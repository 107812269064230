import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyCourseService } from 'src/app/course/myCourse.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';
import { MyUserService } from '../myUser.service';

@Component({
  selector: 'app-updateUser',
  templateUrl: './updateUser.component.html',
  styleUrls: ['./updateUser.component.css']
})
export class UpdateUserComponent implements OnInit {
  selectedCourse: any = [];
  selectedCourse1: any = [];
  courseList: any = [];
  courseDetailsSelected: any = [];
  pageIndex = 1;
  pageSize = 10;
  course: any;
  courseIdlist: any;
  userlistData: any;

  constructor(private _fb: FormBuilder, private _userService: MyUserService, private _activatedRoute: ActivatedRoute, private _router: Router, public _loaderService: LoaderService, private _courseService: MyCourseService) { }

  userlist = this._fb.group({
    email: ['', [Validators.required]],
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    appLanguage: ['', [Validators.required]],
    courseId: ['', Validators.required],
    courseDetails: [''],
    role:['Admin']
  });

  public error = (controlName: string, errorName: string) => {
    return this.userlist.controls[controlName].hasError(errorName);
  }

  ngOnInit() {
    this.getUserList();
    this.getCourseList();
    setTimeout(() => {
      for (let n = 0; n < this.courseDetailsSelected.length; ++n) {
        let courseId = this.courseDetailsSelected[n].courseId;
        var courseObj1 = this.courseList.filter(function (e: any) { if (e.courseId == courseId) { return e } });
        if (courseObj1.length > 0) {
          this.selectedCourse1.push(courseObj1[0])

        }
      }
      this.selectedCourse = this.selectedCourse1;

    }, 1000);



  }

  getUserList() {
    this._userService.getList(this._activatedRoute.snapshot.params.id).subscribe((result: any) => {

      this.userlist = this._fb.group({
        id: [result.id],
        email: [result.email],
        firstname: [result.firstname, [Validators.required]],
        lastname: [result.lastname, [Validators.required]],
        phoneNumber: [result.phoneNumber, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        appLanguage: [result.appLanguage, Validators.required],
        courseId: [result.courseId, Validators.required],
        courseDetails: [result.courseDetails],
        role:[result.role]
      });
      this.userlist.controls['email'].disable();
      this.courseDetailsSelected = result.courseDetails;
    });
  }


  getCourseList() {


    this._courseService.getCourseList(this.pageSize, this.pageIndex, '').subscribe((result: any) => {
      if (result != null) {
        for (let n = 0; n < result.length; ++n) {
          var courseObj = {
            "id": 0,
            "courseId": result[n].id,
            "courseName": result[n].name
          }
          this.courseList.push(courseObj);
        }
      }

    })
  }

  onSubmit() {


    if (this.userlist.invalid) {
      return
    }
    else {

      let selectedId: any = [];
      const data: any = this.userlist.value.courseDetails.forEach((obj: any) => {
        var courseId = '';
        courseId = obj.courseId;
        selectedId.push(courseId);
      });
      this.courseIdlist = selectedId.toString();
      this.userlist.value.courseId = this.courseIdlist
      this.userlistData = this.userlist.value
      this.userlist.controls['id'].setValue(this._activatedRoute.snapshot.params.id);
      this._userService.updateUserList(this.userlistData).subscribe((result: any) => {

        if (result.status == "success") {
          onSweetAlertSuccess(result);
          commonRouting('home/UserList');
        } else if (result.status == "error") {
          onSweetAlertError(result);
        }
      });

    }

  }


}






