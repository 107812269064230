import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyTopicService } from '../myTopic.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { onSelectedFile, onSweetAlertError, onSweetAlertSuccess } from 'src/app/optimiseCode';


@Component({
  selector: 'app-add-topic',
  templateUrl: './add-topic.component.html',
  styleUrls: ['./add-topic.component.css']
})
export class AddTopicComponent implements OnInit {
  selectedFile: any;
  selectedFileName: any;
  selectedFileShow: any;
  constructor(private _fb: FormBuilder, private _router: Router, private _topicService: MyTopicService, private _activatedRoute: ActivatedRoute, public _loaderService: LoaderService) { }
  previousImg: any;
  public courseId: any = localStorage.getItem('CourseId');
  public COURSEID = JSON.parse(this.courseId);
  storeSortOrder: any;
  showImage = false;
  getSortOrder: any;
  parseSortOrder: any;
  autoIncreaseSortOrder: any;
  storeAutoIncreaseSortOrder: any;
  getAutoIncreaseSortOrder: any = localStorage.getItem('TopicAutoIncrease');
  public editMode: boolean = false;

  addTopicList: FormGroup = this._fb.group({
    name: ['', [Validators.required]],
    image: [''],
    imageFile: [''],
    topicType: ['', [Validators.required]],
    courseID: [this.COURSEID, [Validators.required]],
    isActive: [true, [Validators.required]],
    hindiName: [''],
    gujName: [''],
    sortOrder: [(localStorage.getItem('TopicSortOrder') == null ? 1 : this.getAutoIncreaseSortOrder), [Validators.required, Validators.pattern(/^[0-9]\d*$/)]]
  });


  public hasError = (controlName: string, errorName: string) => {
    return this.addTopicList.controls[controlName].hasError(errorName);
  }


  ngOnInit(): void {
    this._topicService.getList(this._activatedRoute.snapshot.params.id).subscribe((result: any) => {

      this.editMode = true;
      this.previousImg = result.image;
      this.addTopicList = this._fb.group({
        id: [result.id],
        name: [result.name, [Validators.required]],
        image: [result.image],
        imageFile: [result.imageFile],
        topicType: [result.topicType, [Validators.required]],
        courseID: [result.courseID, [Validators.required]],
        isActive: [result.isActive, [Validators.required]],
        hindiName: [result.hindiName],
        gujName: [result.gujName],
        sortOrder: [result.sortOrder, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]]

      });

    });

  }

  onImageSubmit(event: any) {
    this.showImage = true
    this.selectedFile = <File>event.target.files[0];
    this.selectedFileName = onSelectedFile(event);

    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedFileShow = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubmit() {
    if (this.addTopicList.invalid) {
      return;
    }

    if (this._activatedRoute.snapshot.params.id == "", this._activatedRoute.snapshot.params.id == "0", this._activatedRoute.snapshot.params.id == null) {
      const formData = new FormData();
      formData.append('name', this.addTopicList.value.name);
      formData.append('topicType', this.addTopicList.value.topicType);
      formData.append('courseID', this.addTopicList.value.courseID);
      formData.append('isActive', this.addTopicList.value.isActive);
      formData.append('hindiName', this.addTopicList.value.hindiName);
      formData.append('gujName', this.addTopicList.value.gujName);
      formData.append('sortOrder', this.addTopicList.value.sortOrder);
      formData.append('image', this.selectedFileName);
      formData.append('imageFile', this.selectedFile);


      this._topicService.postTopicList(formData).subscribe((result: any) => {


        this.storeSortOrder = localStorage.setItem('TopicSortOrder', this.addTopicList.value.sortOrder);
        this.getSortOrder = localStorage.getItem('TopicSortOrder');
        this.parseSortOrder = JSON.parse(this.getSortOrder);

        this.autoIncreaseSortOrder = this.parseSortOrder + 1;

        this.storeAutoIncreaseSortOrder = localStorage.setItem('TopicAutoIncrease', this.autoIncreaseSortOrder);

        if (result.status == "success") {
          onSweetAlertSuccess(result);
          this._router.navigate(['/home/CourseTopic/', JSON.parse(this.courseId)]);
        } else if (result.status == "Already Exist") {
          onSweetAlertError(result);
        }
      })
    }

    else {
      this.addTopicList.controls['id'].setValue(this._activatedRoute.snapshot.params.id);

      const formData = new FormData();
      formData.append('id', this.addTopicList.value.id);
      formData.append('name', this.addTopicList.value.name);
      formData.append('topicType', this.addTopicList.value.topicType);
      formData.append('courseID', this.addTopicList.value.courseID);
      formData.append('isActive', this.addTopicList.value.isActive);
      formData.append('hindiName', this.addTopicList.value.hindiName);
      formData.append('gujName', this.addTopicList.value.gujName);
      formData.append('sortOrder', this.addTopicList.value.sortOrder);
      formData.append('image', this.selectedFileName);
      formData.append('imageFile', this.selectedFile);
      if (this.selectedFileName == undefined) {
        formData.append('image', this.previousImg);
      } else {
        formData.append('image', this.selectedFileName);
      }


      this._topicService.updateTopicList(formData).subscribe((result: any) => {


        if (result.status == "success") {
          onSweetAlertSuccess(result);
          this._router.navigate(['/home/CourseTopic/', JSON.parse(this.courseId)]);
        }
      })

    }
  }

  clicktobackList() {
    this._router.navigate(['/home/CourseTopic/', JSON.parse(this.courseId)]);
  }

}
