import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MyCourseService } from 'src/app/course/myCourse.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { commonRouting, onSweetAlertError1, onSweetAlertSuccess } from 'src/app/optimiseCode';
import { MyUserService } from '../myUser.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})

export class UserComponent implements OnInit {

  pageSize = 10;
  pageIndex = 1;
  courseList: any;

  constructor(private _fb: FormBuilder, private _userService: MyUserService, public _loaderService: LoaderService, private _courseService: MyCourseService) { }
  isLoading = true
  userlist = this._fb.group({
    email: ['', [Validators.required], [Validators.email]],
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    password: ['', Validators.required],
    appLanguage: ['', [Validators.required]],
    courseId: ['', [Validators.required]],
    role:['Admin']
  });

  public error = (controlName: string, errorName: string) => {
    return this.userlist.controls[controlName].hasError(errorName);
  }
  hide = true;

  ngOnInit() {
    this.isLoading = false
    this.getCourseList()
  }


  getCourseList() {
    this._courseService.getCourseList(this.pageSize, this.pageIndex, '').subscribe((result: any) => {
      this.courseList = result;
    }
    )
  }

  onSubmit() {

    if (this.userlist.invalid) {
      this.isLoading = false
      return
    }
    else {
      this.userlist.value.courseId = this.userlist.value.courseId.toString()

      this._userService.postUserList(this.userlist.value).subscribe((result: any) => {
        this.isLoading = true
        if (result.status == "Success") {
          onSweetAlertSuccess(result);
          commonRouting('login')
          this.isLoading = false
        }
      }, err => {

        if (err.error.status == "Error") {
          onSweetAlertError1(err);
          this.isLoading = false
        }
      }
      );
    }

  }


}





