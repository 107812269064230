<div class="mt-5">
  <div class="row">
    <div class="col-12">
      <mat-card>
        <div>
          <h2 class="title ms-3">Update User</h2>
          <form id="form" [formGroup]="userlist">
            <div class="d-flex">
              <div class="col-6">

                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Email ID</mat-label>
                  <input matInput formControlName="email" pattern="^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                  <mat-error *ngIf="error('email','required')">Email is required</mat-error>
                  <mat-error *ngIf="error('email','pattern')">Please Enter Valid Email Id</mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>First Name</mat-label>
                  <input matInput formControlName="firstname">
                  <mat-error *ngIf="error('firstname','required')">First Name is required</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="d-flex">
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="lastname">
                  <mat-error *ngIf="error('lastname','required')">Last Name is required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select Language</mat-label>
                  <mat-select disableRipple formControlName="appLanguage">
                    <mat-option value="English">English</mat-option>
                    <mat-option value="Hindi">Hindi</mat-option>
                    <mat-option value="Gujarati">Gujarati</mat-option>
                  </mat-select>
                  <mat-error *ngIf="error('appLanguage','required')">app Language is required</mat-error>
                </mat-form-field>



              </div>
              <div class="col-6">

                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Phone Number</mat-label>
                  <input matInput formControlName="phoneNumber">
                  <mat-error *ngIf="error('phoneNumber','required')">Phone Number is required</mat-error>
                  <mat-error *ngIf="error('phoneNumber','pattern')">Please Enter 10 digits only</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Select Course</mat-label>
                  <mat-select formControlName="courseDetails" multiple [(ngModel)]="selectedCourse"
                    name="selectedCourse">
                    <mat-option *ngFor="let course of courseList" [value]="course">{{course.courseName}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="error('courseDetails','required')">Please select course</mat-error>
                </mat-form-field>
              </div>

            </div>

            <div class="ms-3">
              <button mat-raised-button color="primary" class="me-2" type="submit" (click)="onSubmit()">Submit</button>

              <button mat-stroked-button color="primary" [routerLink]="['/home/UserList']">Cancel</button>
            </div>
          </form>

        </div>
      </mat-card>
    </div>
  </div>
</div>

<!-- loading spinner -->
<div class="spinner" *ngIf="_loaderService.isLoading | async">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>