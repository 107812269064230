<div class="my-2">
  <div class="row">
    <div class="col-12">
      <mat-card>
        <div class="container">
          <h2 class="title ms-3">
            {{ editMode ? "Edit" : "Add" }} Course
          </h2>
          <form id="form"  [formGroup]="addCourseList" (ngSubmit)="onSubmit()">
            <div class="d-flex ">
              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>English Name</mat-label>
                  <input matInput formControlName="name" />
                  <mat-error *ngIf="hasError('name', 'required')">Please enter English Course Name</mat-error>
                </mat-form-field>

                <div>
                  <mat-label><b>Course Description :- </b></mat-label>
                  <editor formControlName="shortDescription" class="w-100" style=" border-radius: 8px"
                    apiKey="your-api-key" [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',
                          height: 150,
                          menubar: true,
                          content_style:
                            'body { background: #f1f2f7; color: black; font-family: verdana; }',
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | styleselect | fontsizeselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help'
                        }"></editor>
                  <mat-error *ngIf="hasError('shortDescription', 'required')">Note : This field is required</mat-error>
                </div>
              </div>

              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label> Hindi Name</mat-label>
                  <input matInput formControlName="hindiName" />
                  <mat-error *ngIf="hasError('hindiName', 'required')">Please enter Hindi Course Name</mat-error>
                </mat-form-field>

                <div>
                  <mat-label><b>Hindi Course Description :-</b></mat-label>
                  <editor formControlName="hindiShortDescription" class="w-100" style=" border-radius: 8px"
                    apiKey="your-api-key" [init]="{
                      base_url: '/tinymce',
                      suffix: '.min',
                      height: 150,
                      menubar: true,
                      content_style:
                        'body { background: #f1f2f7; color: black; font-family: verdana; }',
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | styleselect | fontsizeselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help'
                    }"></editor>
                  <mat-error *ngIf="hasError('hindiShortDescription', 'required')">Note : This field is required
                  </mat-error>
                </div>
              </div>

            </div>

            <div class="d-flex">


              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label> Gujarati Name</mat-label>
                  <input matInput formControlName="gujName" />
                  <mat-error *ngIf="hasError('gujName', 'required')">Please enter Gujarati Course Name</mat-error>
                </mat-form-field>

                <div>
                  <mat-label><b>Gujarati Course Description :- </b></mat-label>
                  <editor formControlName="gujShortDescription" class="w-100" style=" border-radius: 8px"
                    apiKey="your-api-key" [init]="{
                      base_url: '/tinymce',
                      suffix: '.min',
                      height: 150,
                      menubar: true,
                      content_style:
                        'body { background: #f1f2f7; color: black; font-family: verdana; }',
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | styleselect | fontsizeselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help'
                    }"></editor>
                  <mat-error *ngIf="hasError('gujShortDescription', 'required')">Note : This field is required
                  </mat-error>
                </div>

              </div>

              <div class="col-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Type</mat-label>
                  <mat-select disableRipple formControlName="type">
                    <mat-option value="Courses">Courses</mat-option>
                    <mat-option value="Extra Learning">Extra Learning</mat-option>
                  </mat-select>
                  <mat-error *ngIf="hasError('type', 'required')">Please select Course Type</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Sort Order</mat-label>
                  <input matInput formControlName="sortOrder" />
                  <mat-error *ngIf="hasError('sortOrder', 'required')">Sort Order is required</mat-error>
                  <mat-error *ngIf="hasError('sortOrder', 'pattern')">Only numbers are allowed</mat-error>
                </mat-form-field>
                <div>
                  <input type="file" class="w-100" #file  (change)="onImageSubmit($event)" />

                  <h2>{{ previousImg }}</h2>
                  <p *ngIf="previousImg">Previous Image</p>
                  <img *ngIf="previousImg" src="https://interview-test.sparkleweb.co.in/content/images/course/{{
                          previousImg
                        }}"  width="100px" height="100px" />


                  <span class="mx-2"></span>
                  <img [src]="selectedFileShow" width="100px"  height="100px" alt="image"
                    *ngIf="showImage" />

                  <h2>{{ selectedFileName }}</h2>

                </div>
                <mat-slide-toggle formControlName="isActive" color="primary">Status</mat-slide-toggle>
              </div>

            </div>

            <div class="ms-3 mt-2">
              <button mat-raised-button color="primary" class="button1 me-2" type="submit">
                {{ editMode ? "Update" : "Submit" }}
              </button>
              <button mat-stroked-button color="primary" (click)="clicktobackList()">
                Cancel
              </button>
            </div>

          </form>
        </div>
      </mat-card>

    </div>
  </div>

</div>


<!-- loading spinner -->
<div class="spinner" *ngIf="_loaderService.isLoading | async"
  style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>