<div class="mt-4">
    <div class="row">
        <div class="col-12">
            <mat-card>
                <div>
                    <div class="container">
                        <h2 class="title ms-3 "> {{editMode?'Edit':'Add'}} Quiz</h2>
                        <form id="form" [formGroup]="addQuizList" (ngSubmit)="onSubmit()">
                            <div class="d-flex">
                                <div class="col-6">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Question</mat-label>
                                        <input matInput formControlName="question">
                                        <mat-error *ngIf="hasError('question', 'required')"> Please enter Question
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field appearance="outline" class="w-100" hidden>
                                        <mat-label>Chapter Id</mat-label>
                                        <input matInput formControlName="chapterID">
                                        <mat-error *ngIf="hasError('chapterID', 'required')">Please enter Chapter Id
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label> Hindi Question</mat-label>
                                        <input matInput formControlName="hindiQuestion">
                                        <mat-error *ngIf="hasError('hindiQuestion', 'required')">Please enter Hindi
                                            Question </mat-error>
                                    </mat-form-field>

                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="col-6">

                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label> Gujarati Question</mat-label>
                                        <input matInput formControlName="gujQuestion">
                                        <mat-error *ngIf="hasError('gujQuestion', 'required')">Please enter Gujarati
                                            Question</mat-error>
                                    </mat-form-field>

                                </div>
                                <div class="col-6">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>SortOrder</mat-label>
                                        <input matInput formControlName="sortOrder">
                                        <mat-error *ngIf="hasError('sortOrder', 'required')">SortOrder is required
                                        </mat-error>
                                        <mat-error *ngIf="hasError('sortOrder', 'pattern')">Only numbers are allowed
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="col-6">
                                    <p>

                                        <mat-slide-toggle formControlName="isActive" color="primary">Status</mat-slide-toggle>
                                    </p>
                                </div>
                                <div class="col-6">
                                    <div>
                                        <input type="file" class="w-100" formControlName="imageFile"
                                            (change)="onImageSubmit($event)" />
                                        <h2>{{previousImg}}</h2>
                                        <p *ngIf="previousImg">Previous Image</p>
                                        <img *ngIf="previousImg"
                                            src="https://interview-test.sparkleweb.co.in/content/images/quiz/{{previousImg}}"
                                            formControlName="image" width="100px" height="100px">

                                        <span class="mx-2"></span>
                                        <img [src]="selectedFileShow" formControlName="image" width="100px"
                                            height="100px" *ngIf="showImage">
                                        <h2>{{selectedFileName}}</h2>
                                    </div>
                                </div>
                            </div>

                            <div class="ms-3 ">
                                <button mat-raised-button color="primary" class="me-2"
                                    type="submit">{{editMode?'Update':'Submit'}}</button>

                                <button mat-stroked-button color="primary" (click)="clicktobackList()">Cancel</button>
                            </div>
                        </form>


                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>

<!-- loading spinner -->
<div class="spinner" *ngIf="_loaderService.isLoading | async"
    style="display: flex; justify-content: center; align-items: center ; ">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>