<div class="mt-5">
  <div class="row">
    <div class="col-12">
      <mat-card>
        <div class="container">
          <div class="">
            <h2 class="title ms-3">
              {{ editMode ? "Update" : "Add" }} Question
            </h2>
            <form id="form" [formGroup]="addTopicList" (ngSubmit)="onSubmit()">
              <div class="d-flex">
                <div class="col-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label> Name</mat-label>
                    <input matInput formControlName="name" />
                    <mat-error *ngIf="hasError('name', 'required')">Please enter Question Name
                    </mat-error>
                  </mat-form-field>

                </div>
                <div class="col-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label> Hindi Name</mat-label>
                    <input matInput formControlName="hindiName" />
                    <mat-error *ngIf="hasError('hindiName', 'required')">Please enter Hindi Question Name</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="d-flex">
                <div class="col-6">
                  <mat-form-field appearance="outline" class="w-100" hidden>
                    <mat-label>Course Id</mat-label>
                    <input matInput formControlName="courseID" />
                    <mat-error *ngIf="hasError('courseID', 'required')">Please enter Course Id</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label> Gujarati Name</mat-label>
                    <input matInput formControlName="gujName" />
                    <mat-error *ngIf="hasError('gujName', 'required')">Please enter Gujarati Question Name
                    </mat-error>
                  </mat-form-field>


                </div>
                <div class="col-6">

                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Level Type</mat-label>
                    <mat-select disableRipple formControlName="topicType">
                      <mat-option value="List">List</mat-option>
                      <mat-option value="Tiles">Tiles</mat-option>
                    </mat-select>
                    <mat-error *ngIf="hasError('topicType', 'required')">Please select Question Type</mat-error>
                  </mat-form-field>

                </div>
              </div>

              <div class="d-flex">
                <div class="col-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Sort Order</mat-label>
                    <input matInput formControlName="sortOrder" />
                    <mat-error *ngIf="hasError('sortOrder', 'required')">SortOrder is required</mat-error>
                    <mat-error *ngIf="hasError('sortOrder', 'pattern')">Only numbers are allowed</mat-error>
                  </mat-form-field>
                  <p>
                    <mat-slide-toggle formControlName="isActive" color="primary">Status</mat-slide-toggle>
                  </p>
                </div>
                <div class="col-6">
                  <input type="file" class="w-100" formControlName="imageFile" (change)="onImageSubmit($event)" />
                  <h2>{{previousImg}}</h2>
                  <p *ngIf="previousImg">Previous Image</p>
                  <img *ngIf="previousImg"
                    src="https://interview-test.sparkleweb.co.in/content/images/coursetopic/{{previousImg}}"
                    formControlName="image" width="100px" height="100px">
                  <span class="mx-2" *ngIf="previousImg"></span>
                  <img [src]="selectedFileShow" formControlName="image" width="100px" height="100px" *ngIf="showImage">
                  <h2>{{selectedFileName}}</h2>
                </div>
              </div>

              <div class="ms-3">
                <button mat-raised-button color="primary" class="me-2" type="submit">
                  {{ editMode ? "Update" : "Submit" }}
                </button>

                <button mat-stroked-button color="primary" (click)="clicktobackList()">
                  Cancel
                </button>
              </div>
            </form>

          </div>

        </div>
      </mat-card>
    </div>
  </div>
</div>
<!-- loading spinner -->
<div class="spinner" *ngIf="_loaderService.isLoading | async"
  style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>