import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MyAccountService } from '../../myAccount.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  hide2 =true;
  hide3 =true;
  email: any;
  token: any;

  constructor( private  _fb : FormBuilder ,private _myAccountService:MyAccountService 
    ,private route:ActivatedRoute, private router:Router) { }

  resetPasswordForm = this._fb.group({
    token:['',Validators.required],
     email :['', [Validators.required, Validators.email]],
     newPassword:['',[Validators.required ,   Validators.pattern(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}'
     ),]],
     confirmNewPssword:['',[Validators.required,]]},
   {
     validators:MustMatch('newPassword', 'confirmNewPssword')
   }
   );
   public error = (control:string, error:string) => {
    return this.resetPasswordForm.controls[control].hasError(error);
  }

  ngOnInit(): void {
    localStorage.clear();
    this.route.queryParams.subscribe(params=>{
      this.email = params.email
      this.token = params.token.replace(/ /g, "\+");
    })
  }

  
  postResetPassword()
  {
    this._myAccountService.postResetpassword(this.resetPasswordForm.value).subscribe((res:any)=>
    {
      if (res.status == 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: res.message,
        }).then(res => {
          if (res.isConfirmed)
            this.router.navigate(['login']);
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: res.message,
        });
      }
            
      
    })
  }

}
export function MustMatch(controlName: any, matchingControlName: any){
      
  {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
  
        if (matchingControl.errors && !matchingControl.errors.MustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        // set error on matchingControl if validation fails
        else if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ MustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
  }
}
